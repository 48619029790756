<template>
  <div style="height: 100%;width: 100%">
    <el-tabs class="tabs" v-model="activeName" @tab-click="handleClick" style="height: 100%">
      <el-tab-pane label="客服管理" name="first">
        <customerService v-if="activeName == 'first'"  :jurisdiction="jurisdiction"/>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import customerService from "./components/customerService.vue";
import { mapState } from 'vuex';

export default {
  name: "serviceCoutomerManagement",
  components: {
    customerService,
  },

  data() {
    return {
      activeName: "first",
      jurisdiction:false,
    };
  },
  computed: {
    ...mapState(['routerArr']),

  },
  created(){
    if(this.$route.query.name){
      this.activeName = this.$route.query.name
    }
  },
  watch:{
    routerArr(val){
      this.jurisdiction = val.includes("040")
      console.log(this.jurisdiction,'this.jurisdiction')
    },
  },
  methods: {
    handleClick(tab) {
      const { name } = tab;
      this.activeName = name;
    },
  },
};
</script>

<style lang="scss" scoped>
  /deep/.el-tabs__content{
    height: 90%;
  }
  /deep/.el-tab-pane{
    height: 100%;
  }
</style>




