import httpUtil from "@/utils/httpUtil";

/** 添加系统设置*/
export function addSystemSetting(params,type) {
    return httpUtil.post("/api/assPc/setting/addSystemSetting", params, type)
}
/** 修改系统设置 */
export function upSystemSetting(params,type) {
    return httpUtil.post("/api/assPc/setting/upSystemSetting", params, type)
}
/** 删除系统设置 */
export function delSystemSetting(params,type) {
    return httpUtil.post("/api/assPc/setting/delSystemSetting", params, type)
}
/** 获取系统设置 */
export function selectSystemSettingList(params,type) {
    return httpUtil.post("/api/assPc/setting/selectSystemSettingPageList", params, type)
}
/** 查看系统设置 */
export function querySystemSettingByAssId(params,type) {
    return httpUtil.post("/api/assPc/setting/querySystemSettingByAssId", params, type)
}

/** 添加用户反馈*/
export function addUserFeedback(params,type) {
    return httpUtil.post("/api/assPc/setting/addUserFeedback", params, type)
}
/** 修改用户反馈 */
export function upUserFeedback(params,type) {
    return httpUtil.post("/api/assPc/setting/upUserFeedback", params, type)
}
/** 删除用户反馈 */
export function delUserFeedback(params,type) {
    return httpUtil.post("/api/assPc/setting/delUserFeedback", params, type)
}
/** 获取用户反馈 */
export function selectUserFeedbackList(params,type) {
    return httpUtil.post("/api/assPc/setting/selectUserFeedbackPageList", params, type)
}

/** 添加客服 */
export function addMessageCustomer(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/addMessageCustomer", params, type)
}
/** 客服列表 */
export function queryListByPageMesageCustomer(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/queryListByPageMesageCustomer", params, type)
}
/** 修改客服 */
export function updateMessageCustonmerById(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/updateMessageCustonmerById", params, type)
}





