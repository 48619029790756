<template>
  <div class="content_wrap" v-loading="loading">
    <div class="kefu_wrap" v-if="!kefuList.length && !loading">
      <img src="../../../assets/images/kefuguanli.png" alt="">
      <div class="add">还未添加客服请添加客服</div>
      <div>
        <el-button style="background: rgba(81,203,205,0.1);border: 1px solid #51CBCD;border-radius: 4px;color: #51CBCD;" @click="add">添加客服</el-button>
      </div>
    </div>

    <div class="kefu_content" v-if="kefuList.length && !loading">
      <div v-for="(item,index) in kefuList" :key="index" class="kefu_item">
        <div class="kefu_title">
          <div class="kefu_title_left">
            <img src="../../../assets/images/kefuHead.png" alt="">
            <div>
              <span>{{item.name}}</span>
              <span>绑定会员：{{item.memberName}}</span>
            </div>
          </div>
          <div class="kefu_title_right">
            <el-button style="background: rgba(81,203,205,1);border-radius: 4px;color: #FFFFFF;" @click="edit(item),editUpdate = true">编辑</el-button>
          </div>
        </div>
        <div class="kefu_date_wrap">
          <div>
            <span>创建时间:</span>
            <span>{{item.createTime}}</span>
          </div>
          <div>
            <span>修改时间:</span>
            <span>{{item.updateTime}}</span>
          </div>
        </div>
      </div>
    </div>

    <el-dialog
        title="添加客服"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="50%"
        center
        @close="close"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="150px"
      >
        <el-form-item label="客服名称" prop="title">
          <el-input clearable v-model="ruleForm.title" maxlength="50" show-word-limit></el-input>
        </el-form-item>
        <el-form-item prop="memberId" label="绑定会员">
          <el-select
              clearable
              v-model="ruleForm.memberId"
              style="width: 100%"
              placeholder="请选择需要绑定的会员"
          >
            <el-option
                v-for="(item) in memberList"
                :key="item.memberId"
                :label="item.userName"
                :value="item.memberId"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="centerDialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="determine">确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {Base64 as base64} from "js-base64";
import {
  selectMemberPageList,
} from "@/api/members";
import {
  addMessageCustomer,
  queryListByPageMesageCustomer,
  updateMessageCustonmerById
} from "@/api/setting";
export default {
  name: "customerService",
  data(){
    return{
      customerId:'',
      kefuList:[],
      editUpdate:true,
      memberList:[],
      loading:false,
      centerDialogVisible:false,
      ruleForm:{
        title: "",
        memberId:''
      },
      rules:{
        title: [{ required: true, message: "请输入名称", trigger: "blur" }],
        memberId: [{ required: true, message: "请选择会员", trigger: "blur" }],
      }
    }
  },
  mounted() {
    this.queryPage()
    this.queryPage1()
  },
  methods:{
    async queryPage() {
      let data = {
        pageSize: 9999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
        examineStatusList: '0',
        userStatus: 1,
      };
      try {
        this.loading = true;
        const result = await selectMemberPageList(data);
        if(result == 200){
          const {total, list} = result.data.pageInfo;
          this.loading = false;
          this.memberList = list;
        }else{
          this.loading = false;
          this.memberList = [];
        }
      } catch (error) {
        this.loading = false;
        this.memberList = [];
      }
    },
    async queryPage1() {
      let data = {
        pageSize: 9999,
        pageNum: 1,
        associationId: localStorage.getItem("associationId"),
      };
      try {
        this.loading = true;
        const result = await queryListByPageMesageCustomer(data);
        if(result.code == 200){
          const {total, list} = result.data.pageInfo;
          for (let i in list) {
            list[i].createTime = this.renderTime(list[i].createTime)
            list[i].updateTime = this.renderTime(list[i].updateTime)
          }
          this.loading = false;
          this.kefuList = list;
        }else{
          this.loading = false;
          this.kefuList = [];
        }
      } catch (error) {
        this.loading = false;
        this.kefuList = [];
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    add(){
      this.centerDialogVisible = true
      this.editUpdate = false
    },
    close(){
      (this.ruleForm = {
        title: "",
        memberId: ""
      }),
      this.centerDialogVisible = false;
    },
    determine(){
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          try {
            let data = {
              associationId: localStorage.getItem("associationId"),
              name:this.ruleForm.title,
              memberId:this.ruleForm.memberId,
              isDel:0,
            };
            this.$message({
              message: "正在保存",
            });
            if (this.editUpdate) {
              data.customerId = this.customerId
              await updateMessageCustonmerById(data);
            } else {
              await addMessageCustomer(data);
            }
            this.centerDialogVisible = false;
            this.$message.closeAll();
            this.$message({
              message: "保存成功",
              type: "success",
            });
            await this.queryPage1();
          } catch (error) {
            this.$message.closeAll();
            this.$message({
              message: error.message,
              type: "error",
            });
            this.centerDialogVisible = false;
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    edit(val) {
      let data = JSON.parse(JSON.stringify(val));
      this.centerDialogVisible = true;
      this.ruleForm.title = data.name;
      this.ruleForm.memberId = data.memberId;
      this.customerId = data.customerId
    },
  }
}
</script>

<style scoped>
  .content_wrap{
    width: 100%;
    height: 100%;
    background: #FFFFFF;
  }
  .kefu_wrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .kefu_wrap>img{
    width: 544px;
    height: 368px;
    margin-top: 100px;
  }
  .kefu_wrap .add{
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    opacity: 0.6;
    margin: 65px 0 35px 0;
  }
  .kefu_content{
    padding: 40px 60px;
    display: flex;
    flex-direction: column;
  }
  .kefu_title{
    display: flex;
    justify-content: space-between;
  }
  .kefu_title_left{
    display: flex;
  }
  .kefu_title_left>img{
    width: 80px;
    height: 80px;
    margin-right: 20px;
  }
  .kefu_title_left>div{
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-evenly;
    height: 100%;
  }
  .kefu_title_left>div>span:nth-child(1){
    font-size: 20px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #333333;
  }
  .kefu_title_left>div>span:nth-child(2){
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #595959;
  }
  .kefu_item{
    box-shadow: 0px 10px 30px 0px rgba(49,49,49,0.06);
    padding: 30px;
  }
  .kefu_date_wrap{
    display: flex;
    background: #F6F6F6;
    border-radius: 4px;
    padding: 15px 20px;
    margin-top: 30px;
  }
  .kefu_date_wrap>div{
    display: flex;
    width: 50%;
  }
  .kefu_date_wrap>div>span:nth-child(1){
    align-self: center;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #808080;
  }
  .kefu_date_wrap>div>span:nth-child(2){
    align-self: center;
    font-size: 14px;
    font-family: Arial;
    font-weight: 400;
    color: #333333;
    margin-left: 20px;
  }
</style>
