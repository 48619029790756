import httpUtil from "@/utils/httpUtil";

/** 新增会员 */
export function addMember(params,type) {
    return httpUtil.post("/api/assPc/member/addMember", params, type)
}
/** 查询会员 */
export function selectMemberPageList(params,type) {
    return httpUtil.post("/api/assPc/member/selectMemberPageList", params, type)
}
/** 删除会员 */
export function delMember(params,type) {
    return httpUtil.post("/api/assPc/member/delMember", params, type)
}
/** 修改会员 */
export function upMember(params,type) {
    return httpUtil.post("/api/assPc/member/upMember", params, type)
}
/** 会员详情 */
export function queryMemberById(params,type) {
    return httpUtil.post("/api/assPc/member/queryMemberById", params, type)
}
/** 审核详情 */
export function selectExaminePageList(params,type) {
    return httpUtil.post("/api/assPc/member/selectExaminePageList", params, type)
}
/**
 * 查询个人履历 */
export function selectResumePageList(params,type) {
    return httpUtil.post("/api/assPc/member/selectResumePageList", params, type)
}
/**
 * 新增个人履历 */
export function addResume(params,type) {
    return httpUtil.post("/api/assPc/member/addResume", params, type)
}
/**
 * 删除个人履历 */
export function delResume(params,type) {
    return httpUtil.post("/api/assPc/member/delResume", params, type)
}
/**
 * 修改个人履历 */
export function upResume(params,type) {
    return httpUtil.post("/api/assPc/member/upResume", params, type)
}
/**
 * 审核详情 */
export function queryJoinApplyDetail(params,type) {
    return httpUtil.post("/api/assPc/association/queryJoinApplyDetail", params, type)
}
/**
 * 入会审批 */
export function approvalMember(params,type) {
    return httpUtil.post("/api/assPc/member/approvalMember", params, type)
}
/**
 * 推荐优秀会员 */
export function pushExcellentMember(params,type) {
    return httpUtil.post("/api/assPc/member/pushExcellentMember", params, type)
}
/**
 * 取消推荐优秀会员 */
export function cancelExcellentMember(params,type) {
    return httpUtil.post("/api/assPc/member/cancelExcellentMember", params, type)
}
/**
 * 查询优秀会员列表 */
export function selectExcellentPageList(params,type) {
    return httpUtil.post("/api/assPc/member/selectExcellentPageList", params, type)
}
/**
 * 优秀会员详情 */
export function queryExcellentById(params,type) {
    return httpUtil.post("/api/assPc/member/queryExcellentById", params, type)
}
/**
 * 修改会员详情 */
export function upExcellent(params,type) {
    return httpUtil.post("/api/assPc/member/upExcellent", params, type)
}
/**
 * 查询代缴费信息 */
export function queryMemberTotalByUserId(params,type) {
    return httpUtil.post("/api/assPc/member/queryMemberTotalByUserId", params, type)
}
/**
 * 新增缴费记录 */
export function addPayHis(params,type) {
    return httpUtil.post("/api/assPc/member/addPayHis", params, type)
}
/**
 * 缴费记录 */
export function selectPayHisPageList(params,type) {
    return httpUtil.post("/api/assPc/member/selectPayHisPageList", params, type)
}
/**
 * 查询粉丝分组 */
export function selectFansGroupPageList(params,type) {
    return httpUtil.post("/api/assPc/member/selectFansGroupPageList", params, type)
}
/**
 * 新增粉丝分组 */
export function addFansGroup(params,type) {
    return httpUtil.post("/api/assPc/member/addFansGroup", params, type)
}
/**
 * 修改粉丝分组 */
export function upFansGroup(params,type) {
    return httpUtil.post("/api/assPc/member/upFansGroup", params, type)
}
/**
 * 删除粉丝分组 */
export function delFansGroup(params,type) {
    return httpUtil.post("/api/assPc/member/delFansGroup", params, type)
}
/**
 * 粉丝列表 */
export function selectAssFansPageList(params,type) {
    return httpUtil.post("/api/assPc/member/selectAssFansPageList", params, type)
}
/**
 * 分组列表 */
export function upUserFansGroup(params,type) {
    return httpUtil.post("/api/assPc/member/upUserFansGroup", params, type)
}
/**
 * 企业主页 */
export function getCompanyInfoByKey(params,type) {
    return httpUtil.post("/api/assPc/member/getCompanyInfoByKey", params, type)
}
/**
 * 代理商列表 */
export function selectAgentCompanyListApi(params,type) {
    return httpUtil.post("/api/crmPc/companyGoods/selectAgentCompanyList", params, type)
}

export function addCompanyServiceApi(params,type) {
    return httpUtil.post("/api/crmPc/companyGoods/addCompanyServiceApi", params, type)
}
export function configureAgenApi(params,type) {
    return httpUtil.post("/api/crmPc/companyGoods/configureAgen", params, type)
}

/**
 * 获取二维码 */
export function selectWxCodeByMemberId(params,type) {
    return httpUtil.post("/api/assPc/member/selectWxCodeByMemberId", params, type)
}

/**
 * 批量设置会长 */
export function createExcellent(params,type) {
    return httpUtil.post("/api/assPc/member/createExcellent", params, type)
}
/**
 * 批量删除会员 */
export function bathDeleteMembers(params,type) {
    return httpUtil.post("/api/assPc/member/bathDeleteMembers", params, type)
}
/**
 * 修改会长风采状态 */
export function updateExcellentStatus(params,type) {
    return httpUtil.post("/api/assPc/member/updateExcellentStatus", params, type)
}
export function upFansGroupByUserId(params,type) {
    return httpUtil.post("/api/assPc/member/upFansGroupByUserId", params, type)
}

//测试会员
export function addTestMember(params,type) {
    return httpUtil.post("/api/assPc/member/addTestMember", params, type)
}

//测试会员
export function delExcellent(params,type) {
    return httpUtil.post("/api/assPc/member/delExcellent", params, type)
}


//新增分组
export function addMemberGroup(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/addMemberGroup", params, type)
}
//分组列表
export function queryMemberGroupPage(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/queryMemberGroupPage", params, type)
}

//修改分组
export function updateMemberGroup(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/updateMemberGroup", params, type)
}
//删除分组
export function delMemberGroup(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/delMemberGroup", params, type)
}

//分组添加会员
export function addMemberGroupReation(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/addMemberGroupReation", params, type)
}

//分组会员列表
export function queryGroupMemberInfo(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/queryGroupMemberInfo", params, type)
}

//分组会员移除
export function removeMemberGroupReation(params,type) {
    return httpUtil.post("/api/assPc/FunctionIPC/removeMemberGroupReation", params, type)
}

//删除会员
export function delMemberInfo(params,type) {
    return httpUtil.post("/api/assPc/member/delMemberInfo", params, type)
}



